@import url("https://fonts.googleapis.com/css?family=Merriweather:300,400,700&display=swap&subset=latin-ext");
@import url("https://fonts.googleapis.com/css?family=Coustard&display=swap");

body {
  min-height: 100%;
  font-family: "Merriweather", serif;

  ul,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none !important;
  }

  .section-hr {
    height: 1px;
    width: 100%;
    background: #d8d8d8;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background: @color2;
      left: 50%;
      transform: translateX(-50%);
      top: -4px;
    }
  }

  .btn {
    padding: 15px 20px;
  }

  .btn-default {
    color: @color1;
    background: none;
    border: 1px solid transparent;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 16px;
    transition: all 0.3s;

    &:hover {
      border-color: @color1;
      color: @color1;
      background: none;
    }
  }

  .btn-border {
    color: @color1;
    background: none;
    border: 1px solid rgba(@color1, 0.6);
    border-radius: 0;
    text-transform: uppercase;
    font-size: 16px;
    transition: all 0.3s;

    &:hover {
      border: 1px solid rgba(@color1, 1);
      color: @color1;
      background: none;
    }
  }

  .m-t-5 {
    margin-top: 5px;
  }

  .m-t-10 {
    margin-top: 10px;
  }

  .m-t-15 {
    margin-top: 15px;
  }

  .m-t-20 {
    margin-top: 20px;
  }

  .m-t-25 {
    margin-top: 25px;
  }

  .m-t-30 {
    margin-top: 30px;
  }

  .m-b-5 {
    margin-bottom: 5px;
  }

  .m-b-10 {
    margin-bottom: 10px;
  }

  .m-b-15 {
    margin-bottom: 15px;
  }

  .m-b-20 {
    margin-bottom: 20px;
  }

  .m-b-25 {
    margin-bottom: 25px;
  }

  .m-b-30 {
    margin-bottom: 30px;
  }

  .m-b-50 {
    margin-bottom: 50px;
  }

  .p-t-5 {
    padding-top: 5px;
  }

  .p-t-10 {
    padding-top: 10px;
  }

  .p-t-15 {
    padding-top: 15px;
  }

  .p-t-20 {
    padding-top: 20px;
  }

  .p-t-25 {
    padding-top: 25px;
  }

  .p-t-30 {
    padding-top: 30px;
  }

  .p-t-80 {
    padding-top: 80px;
  }

  .p-b-5 {
    padding-bottom: 5px;
  }

  .p-b-10 {
    padding-bottom: 10px;
  }

  .p-b-15 {
    padding-bottom: 15px;
  }

  .p-b-20 {
    padding-bottom: 20px;
  }

  .p-b-25 {
    padding-bottom: 25px;
  }

  .p-b-30 {
    padding-bottom: 30px;
  }

  .p-b-50 {
    padding-bottom: 50px;
  }

  .p-b-100 {
    padding-bottom: 100px;
  }

  .p-b-150 {
    padding-bottom: 150px;
  }
}

.fixed-element {
  position: fixed;
  left: 40px;
  bottom: 60px;
  z-index: 444;
  display: flex;
  align-items: center;

  .element-wrapper {
    display: flex;
    align-items: center;
    height: 0;

    .element-active {
      background-color: @color1;
      cursor: pointer;
      width: 240px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color 350ms;

      a {
        span {
          font-size: 14px;
          font-weight: 600;
          color: #fff;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }

    &:hover {
      .element-active {
        background-color: darken(@color1, 15%);
      }
    }
  }
}

.alert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.2);
  z-index: 555;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s;

  .alert-service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    transform: translateX(8px);
    padding: 50px 0;

    .service-inner {
      padding: 50px;
      background: #fff;
      width: 100%;
      box-shadow: 0px 0px 32px 1px rgba(0, 0, 0, 0.15);
      text-align: center;
      border-top: 5px solid #fff;
      position: relative;
      transform: scale(0.8);
      transition: all 0.5s;

      .inner-dismiss {
        top: 10px;
        right: 10px;
        position: absolute;
        border: 0;
        background: none;
        padding: 0;
        cursor: pointer;
        display: flex;
        padding: 5px;
        outline: none;

        img {
          height: 15px;
        }
      }

      &.success {
        border-top: 5px solid #78b042;
      }

      &.warning {
        border-top: 5px solid #d51a27;
      }

      &.info {
        border-top: 5px solid #1a88d5;
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;

    .alert-service {
      .service-inner {
        transform: none;
      }
    }
  }
}

div.section-header {
  nav.navbar {
    min-height: auto;
    margin-bottom: 0;
    border-radius: 0;
    border: none;
    background: none;
    padding: 15px 0;

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:before,
      &:after {
        content: none;
      }
    }

    .navbar-header {
      .navbar-toggle {
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        margin-right: 15px;
        margin-top: 20px;

        span {
          background-color: #333;
        }
      }

      .navbar-brand {
        height: auto;
        padding: 0 0 0 15px;
        margin: 0;
      }
    }

    ul.navbar-nav {
      li {
        a {
          padding: 10px 15px;
          color: rgba(0, 0, 0, 0.5);
          text-transform: uppercase;
          letter-spacing: 0.5px;
          font-size: 14px;
          transition: all 0.3s;
          border-bottom: 1px solid transparent;

          &.color-link {
            color: @color2;
            font-weight: 700;
          }
        }

        &:hover {
          a {
            color: rgba(0, 0, 0, 0.7);
            border-color: @color1;
          }
        }
      }

      // .dropdown:hover .dropdown-menu {
      // 	display: block;
      // }

      // .dropdown-menu {
      // 	right: 0;
      // 	min-width: 250px;

      // 	li {
      // 		a {
      // 			border-color: transparent;
      // 		}

      // 		&:hover .sub-menu {
      // 			visibility: visible;
      // 		}

      // 		&:hover a {
      // 			background-color: @color2;
      // 			color: #fff;
      // 		}
      // 	}

      // 	.sub-menu {
      // 		left: 100%;
      // 		position: absolute;
      // 		top: 0;
      // 		visibility: hidden;
      // 		margin-top: -1px;

      // 		&:before {
      // 			border: 6px solid transparent;
      // 			left: -7px;
      // 			top: 10px;
      // 		}

      // 		&:after {
      // 			border: 6px solid transparent;
      // 			left: 10px;
      // 			top: 11px;
      // 			left: -6px;
      // 		}
      // 	}
      // }
    }
  }
}

div.section-welcome {
  display: flex;
  align-items: center;
  height: 70vh;

  background: linear-gradient(
    135deg,
    #222849 0%,
    #243066 40%,
    #24316a 49%,
    #222849 100%
  );

  .welcome-video {
    width: 55%;
    height: 100%;

    video {
      display: block;
      border: none;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .welcome-slogan {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 45%;

    text-align: right;
    padding: 35px;

    h1 {
      font-size: 30px;
      font-weight: 300;
      color: #fff;
      line-height: 55px;
      letter-spacing: 0.5px;
    }

    h2 {
      margin: 15px 0;
      font-size: 50px;
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
    }

    p {
      font-size: 18px;
      line-height: 34px;
      color: #fff;
    }

    .icons {
      display: flex;
      align-self: flex-end;

      img {
        width: 25px;
        margin: 0 10px;
      }
    }
  }

  .welcome-video {
    width: 55%;
    height: 100%;

    video {
      display: block;
      border: none;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .owl-carousel-slider {
    width: 50%;

    .owl-stage,
    .owl-stage-outer,
    .owl-item {
      height: 100%;
    }

    .item {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        padding: 150px 100px;
        font-size: 38px;
        width: 100%;
        text-align: center;
        line-height: 54px;
        text-transform: uppercase;
        font-weight: 700;
        color: #fff;
        text-shadow: 1px 1px 5px #222;
      }

      &.bg1 {
        background: url(../img/backgrounds/fotolia_102449546.jpg) center;
        background-size: cover;
      }

      &.bg2 {
        background: url(../img/backgrounds/fotolia_84231512.jpg) center;
        background-size: cover;
      }

      &.bg3 {
        background: url(../img/backgrounds/fotolia_103623576.jpg) center;
        background-size: cover;
      }

      &.bg4 {
        background: url(../img/backgrounds/fotolia_134069952.jpg) center;
        background-size: cover;
      }
    }

    .owl-controls {
      display: none;
    }
  }
}

div.section-advantages {
  padding: 80px 0 60px 0;

  .fixed-slogan {
    position: fixed;
    z-index: -100;
    left: 150px;
    top: 10%;

    span {
      display: block;
      font-size: 60px;
      text-transform: uppercase;
      line-height: 50px;
      padding: 10px;
      font-weight: 700;
      color: #f1f1f1;
      opacity: 0.7;
    }
  }

  .advantages-slogan {
    padding-bottom: 50px;
    text-align: center;

    p {
      color: #333;
      font-size: 28px;
      line-height: 55px;
      width: 80%;
      margin: 0 auto;
    }
  }

  h2.section-heading {
    text-align: center;
    font-size: 28px;
    color: #333;
    font-weight: 700;
  }

  .advantages-flexbox {
    display: flex;
    padding-top: 30px;

    .flexbox-service {
      margin-top: 40px;
      width: 25%;
      text-align: center;
      padding: 0;
      border: none;
      background: none;
      outline: none;

      p {
        margin-top: 30px;
        font-weight: 700;
        font-size: 14px;
        color: @color1;
      }

      img {
        width: 50px;
        transition: all 300ms ease;
      }

      &:hover img {
        transform: scale(1.03);
      }
    }
  }
}

div.section-about {
  padding-top: 70px;

  h2.section-heading {
    font-size: 40px;
    text-align: center;
    line-height: 48px;
  }

  .about-flexbox {
    .flexbox-service.video {
      padding: 0;
    }
  }

  .section-description {
    position: relative;
    padding: 20px 0 100px 0;
    text-align: center;

    span {
      font-family: "Coustard", serif;
      position: absolute;
      font-size: 120px;
      color: #ddd;

      &:nth-of-type(1) {
        top: 0;
        left: 0;
      }

      &:nth-of-type(2) {
        top: 80px;
        right: 0;
      }
    }

    p {
      font-size: 22px;
      font-style: italic;
      line-height: 46px;
      color: #333;

      &.author {
        color: @color1;
        font-size: 13px;
      }
    }

    .btn-border {
      margin-top: 40px;
    }
  }

  h3 {
    font-size: 36px;
    text-align: left;
    font-weight: 700;
    display: inline-block;
  }

  .section-news {
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px 0 50px 0;

    .color-element {
      position: absolute;
      z-index: -1;
      right: 275px;
      top: -80px;
      height: 110%;
      width: 3000px;
      background-color: #f1f1f1;
    }

    .flexbox-video {
      width: 50%;
      height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      iframe {
        height: 70%;
        width: 100%;
      }

      .item {
        height: 100%;

        img {
          height: 90%;
          object-fit: contain;
        }
      }

      .btn-border {
        align-self: center;
      }
    }

    .flexbox-news {
      width: 50%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      .news-list {
        list-style-type: none;
        width: 100%;

        li {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 80%;
          padding: 15px 0;
          border-bottom: 1px solid #ddd;
          transition: border-bottom 300ms ease;

          &:hover {
            border-bottom: 1px solid rgba(@color2, 0.5);
          }

          .heading {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-bottom: 10px;
            width: 100%;

            p.date {
              color: @color2;
              font-size: 14px;
              font-style: italic;
            }

            span {
              border-radius: 5px;
              padding: 5px;
              font-size: 11px;
              color: #fff;
              font-weight: 700;
            }
          }

          p.name {
            color: #333;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 1px;
            font-weight: 700;
            padding-bottom: 15px;
          }

          p.description {
            font-size: 13px;
            line-height: 20px;
            color: #333;
            padding-bottom: 10px;
          }

          .btn-border {
            margin-top: 10px;
            padding: 10px 20px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .about-flexbox {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .flexbox-sidebar {
      width: 255px;

      .sidebar-heading {
        margin-top: 40px;
        background: @color2;
        padding: 20px 0;

        p {
          font-size: 16px;
          color: #fff;
          text-transform: uppercase;
          text-align: center;
        }
      }

      img {
        width: 100%;
      }

      ul {
        background: #4a4f55;
        padding: 20px;

        li {
          margin-bottom: 20px;

          a {
            color: #fff;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 900;
            margin-bottom: 10px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .flexbox-service.video {
      padding: 0;
    }

    .flexbox-history {
      margin-top: 40px;
      width: 315px;

      .history-step {
        margin-top: 40px;

        .step-heading {
          p {
            font-size: 18px;

            strong {
              color: @color1;
            }
          }
        }

        .step-text {
          margin-top: 10px;

          p {
            font-size: 14px;
          }
        }

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .flexbox-body {
      margin-top: 40px;
      width: 445px;

      p.body-heading {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;

        &:last-of-type {
          margin-top: 20px;
          margin-bottom: 20px;
          font-weight: 400;
        }
      }

      .body-card {
        margin-top: 20px;
        background: url(../img/backgrounds/fotolia_95931950.jpg) center;
        background-size: cover;
        display: flex;

        ul {
          padding: 15px 30px 80px 15px;
          background: #4a4f55;
          opacity: 0.8;

          li {
            margin-bottom: 15px;
            max-width: 160px;

            a {
              font-weight: 500;
              color: #fff;
              transition: opacity 0.3s;
            }

            &.heading {
              font-family: "Merriweather", serif;

              font-size: 26px;
              color: #fff;
              font-weight: 500;
            }

            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              a {
                opacity: 0.8;
              }
            }
          }
        }
      }

      .body-news {
        .news-service {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px;
          border: 1px solid rgba(0, 0, 0, 0.125);

          .service-image {
            min-width: 45px;
            max-width: 45px;
            height: 45px;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            align-items: center;

            img {
              width: 100%;
            }
          }

          .service-description {
            padding-left: 20px;

            p {
              &.text {
                font-size: 16px;
                color: #333;
                transition: color 0.3s;
              }

              &.author {
                margin-top: 5px;
                color: @color1;
                font-size: 13px;
                text-transform: uppercase;
              }
            }
          }

          &:hover {
            .service-description {
              p.text {
                color: @color1;
              }
            }
          }
        }
      }
    }
  }
}

div.section-offer {
  padding: 60px 0;
  overflow: hidden;

  h2.section-heading {
    font-size: 40px;
    text-align: center;
    font-weight: 500;

    span {
      font-weight: 700;
    }
  }

  .section-description {
    padding-top: 20px;
    text-align: center;

    p {
      font-size: 18px;
      color: #222;
    }
  }

  .offer-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .color-element {
      position: absolute;
      z-index: -1;
      background: #f1f1f1;
      height: 1000px;
      width: 3000px;
      top: -100px;
      left: 558px;
      background-color: #f1f1f1;
    }

    .flexbox-service {
      width: 32%;
      margin-top: 30px;
      overflow: hidden;
      text-align: center;
      padding: 20px 0;
      display: flex;
      justify-content: center;
      background-color: #2c3e8b;
      transition: all 250ms ease-in;

      &:hover p.name {
        transform: translateY(-5px);
      }

      &:hover {
        filter: brightness(1.5);
      }

      .service-image {
        overflow: hidden;
        position: relative;
        height: 150px;
        display: flex;
        justify-content: center;

        img {
          width: 60px;
        }
      }

      p.name {
        transform: translateY(0);
        transition: transform 250ms ease;
        color: #fff;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 1px;
        padding: 0 10px;
      }

      .btn {
        margin-top: 15px;
      }
    }

    .box-hidden {
      width: 30%;
      visibility: hidden;
      background: transparent;
    }
  }

  .button {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}

div.section-temporary {
  margin: 30px 0 80px 0;
  padding: 60px 0;
  background: linear-gradient(
    135deg,
    rgba(34, 40, 73, 1) 0%,
    rgba(36, 48, 102, 1) 40%,
    rgba(36, 49, 106, 1) 49%,
    rgba(34, 40, 73, 1) 100%
  );

  .heading {
    color: #fff;
    text-align: center;
    font-size: 38px;
    line-height: 50px;
    margin-bottom: 30px;
  }

  .section-content {
    h3 {
      color: #fff;
      line-height: 32px;
      font-size: 21px;
      margin-bottom: 20px;
    }

    p {
      color: #fff;
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 10px;

      strong {
        font-size: 18px;
      }
    }

    .contact-item-wrapper {
      padding-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .contact-item {
        width: 28%;
        text-align: center;

        p {
          margin-bottom: 10px;
        }

        .name {
          font-weight: 600;
          font-size: 17px;
        }

        .description {
          font-size: 15px;
          font-weight: 300;
        }

        .number {
          color: @color2;
          font-size: 20px;
          font-weight: 600;

          .fa-phone {
            margin-right: 5px;
          }

          .fa-phone:before {
            font-size: 15px;
          }
        }
      }
    }
  }

  .contact-heading {
    margin: 40px 0 20px 0;

    h3 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  .form-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content-heading {
      width: 35%;

      p {
        color: @color2;
        font-weight: 600;
        margin-top: 10px;
        text-align: left;
      }
    }

    .form {
      padding: 30px 40px;
      width: 60%;

      h3 {
        color: #fff;
        line-height: 32px;
        font-size: 21px;
      }

      .form-element {
        display: flex;
        flex-direction: column;
        align-items: center;

        .input-wrapper,
        input {
          width: 100%;

          &:focus {
            outline: none;
            border-bottom: 1px solid @color2;
          }
        }

        input {
          height: 40px;
          transition: border 350ms;
          border: none;
          border-bottom: 1px solid rgba(#fff, 0.7);
          border-radius: 0;
          background-color: transparent;
          color: #fff;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:focus {
          box-shadow: 0 0 0 30px @color1 inset;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:focus {
          transition: background-color 5000s;
          -webkit-text-fill-color: #fff !important;
        }

        label {
          margin: 15px 0 10px 0;
          text-align: left;
          width: 100%;
          font-size: 14px;
          color: #fff;
        }
      }

      .info {
        margin-top: 20px;

        p {
          color: #fff;
          font-size: 11px;
          text-align: left;
        }
      }

      button {
        margin-top: 40px;
        font-size: 16px;
        padding: 10px;
        color: #fff;
        border: 1px solid #fff;
        transition: color 350ms;

        &:hover {
          color: @color2;
        }
      }

      .checkbox-wrapper {
        cursor: pointer;
        display: block;
        margin-bottom: 0;

        .radio-box {
          position: relative;
          display: flex;
          align-items: center;
          font-size: 13px;
          text-transform: none;
          color: #fff;
          text-align: left;
          transition: all 0.3s;
          padding: 5px 0;
          font-weight: 500;

          &:before {
            content: "";
            min-width: 16px;
            max-width: 16px;
            height: 16px;
            display: block;
            background: url("../img/pictures/checkbox.svg") no-repeat;
            margin-right: 10px;
            transition: all 0.3s;
          }

          p,
          span {
            font-size: 11px;
          }
        }

        input {
          position: absolute;
          width: auto !important;
        }
      }

      .checkbox-wrapper > input:checked + .radio-box {
        font-weight: 700;

        &:before {
          background: url("../img/pictures/checkbox-checked.svg") no-repeat;
        }
      }

      .formal {
        padding: 10px 10px 10px 0;
        height: 65px;
        overflow: auto;
        margin: 10px 0;

        p {
          font-size: 11px;
          line-height: 18px;
          color: #fff;
          text-align: left;
        }
      }
    }
  }
}

div.section-cta {
  padding: 100px 0 80px 0;

  .cta-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-service {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .color-element {
        bottom: -100px;
        position: absolute;
        z-index: -1;
        background: #f1f1f1;
        height: 350px;
        width: 450px;
        right: 0;
      }

      p {
        color: #333;
        font-size: 34px;
        width: 80%;
        margin-bottom: 20px;
        line-height: 55px;

        &:nth-of-type(1) {
          text-align: left;
        }

        &:nth-of-type(2) {
          text-align: right;

          span {
            font-size: 34px;
            display: inline;
            font-weight: 700;
          }
        }
      }

      a {
        color: @color2;
        font-size: 30px;
        width: 80%;
        font-weight: 700;

        &:nth-of-type(1) {
          text-align: left;
        }

        &:nth-of-type(2) {
          text-align: right;
        }

        i {
          font-size: 20px;
        }
      }

      span {
        display: block;
        color: #333;
        font-size: 14px;
      }
    }
  }

  .cta-slogan {
    text-align: center;

    p {
      color: #333;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 40px;
      line-height: 44px;
    }
  }

  .cta-author {
    margin-top: 15px;
    text-align: center;

    p {
      color: #fff;
      text-transform: uppercase;
      font-size: 13px;
    }
  }
}

div.subpage-section-heading {
  padding: 40px 0;
  background: linear-gradient(
    135deg,
    rgba(34, 40, 73, 1) 0%,
    rgba(36, 48, 102, 1) 43%,
    rgba(36, 49, 106, 1) 49%,
    rgba(34, 40, 73, 1) 100%
  );

  h1 {
    font-weight: 300;
    font-size: 45px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

div.aktualnosci-section-content {
  padding-bottom: 50px;
  overflow: hidden;

  .content-flexbox {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;

    h2,
    h3 {
      color: #333;
      font-size: 21px;
      margin-bottom: 20px;

      span {
        font-weight: 700;
      }
    }

    h3 {
      font-size: 22px;
    }

    .flexbox-body {
      margin-top: 30px;
      width: 70%;

      .btn {
        padding: 5px 8px;
        font-size: 15px;
      }

      .news-flexbox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .flexbox-service {
          position: relative;
          width: 32%;
          background-color: #fff;
          margin-top: 30px;

          .service-description {
            position: absolute;
            bottom: -15px;
            right: 0;
            width: 88%;
            height: 160px;
            border: 1px solid rgba(0, 0, 0, 0.125);
            padding: 5px 10px 0 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            background-color: #fff;

            p.date {
              color: @color2;
              font-size: 12px;
              font-style: italic;
            }

            p.name {
              color: #333;
              font-size: 12px;
              line-height: 20px;
              font-weight: 700;
            }

            .btn-border {
              margin: 10px;
              padding: 6px 10px;
              width: 120px;
              font-size: 12px;
            }
          }

          &:hover .service-image img {
            filter: grayscale(0);
          }

          .service-image {
            overflow: hidden;
            height: 180px;

            img {
              width: 100%;
              height: 100%;
              filter: grayscale(0.6);
              transition: 300ms filter ease;
            }
          }
        }

        .box-hidden {
          width: 30%;
          visibility: hidden;
          background: transparent;
        }
      }

      .single-news-service {
        margin-top: 20px;
        text-align: center;
        width: 100%;

        .service-image {
          margin: 0 auto;
          max-width: 100%;
          width: 500px;

          img {
            width: 100%;
            border-radius: 10px;
            filter: grayscale(60%);
          }
        }

        .service-description {
          margin-top: 30px;

          h3 {
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 22px;
            line-height: 38px;
          }

          p {
            font-size: 14px;
            line-height: 28px;
            color: #333;
            margin-top: 20px;
            text-align: left;
          }
        }
      }
    }

    .flexbox-sidebar {
      margin-top: 30px;
      width: 20%;

      .list-group {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .color-element {
          position: absolute;
          z-index: -1;
          background: #f1f1f1;
          height: 120%;
          width: 3000px;
          top: -60px;
          left: 95px;
          background-color: #f1f1f1;
        }

        .video-wrapper {
          width: 100%;
          height: 190px;
          margin-top: 15px;

          &:first-of-type {
            margin-top: 0;
          }

          iframe {
            width: 100%;
            height: 100%;
          }
        }

        .fancybox-item {
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  ul.pagination-nav {
    margin-top: 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      a {
        padding: 8px 13px;
        color: @color1;
        transition: all 0.3s;
      }

      &.active {
        a {
          background: @color2;
          color: #fff;
        }
      }

      &:hover {
        a {
          background: #f1f1f1;
        }
      }
    }
  }
}

div.prawo-dla-firm-section-content,
div.prawo-na-co-dzien-section-content {
  overflow: hidden;

  .heading {
    position: relative;

    h2 {
      font-size: 40px;
      line-height: 48px;
      text-align: center;

      span {
        font-weight: 700;
      }
    }

    .color-element {
      position: absolute;
      z-index: -1;
      background: #f1f1f1;
      height: 550px;
      width: 3000px;
      top: -30px;
      left: 562px;
    }
  }

  h3 {
    font-size: 36px;
    text-align: center;

    span {
      font-weight: 700;
    }
  }

  .section-description {
    margin-top: 30px;
    text-align: center;

    p {
      font-size: 15px;
      line-height: 32px;
      color: #333;
    }

    li {
      line-height: 28px;
    }
  }

  .content-category {
    padding-top: 60px;

    .about-flexbox {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 30px;

      .flexbox-service {
        width: 32%;
        margin: 6px;
        overflow: hidden;
        text-align: center;
        padding: 20px 0;
        display: flex;
        justify-content: center;
        background-color: #263679;
        transition: all 250ms ease-in;

        &.video {
          padding: 0;

          video {
            display: block;
            border: none;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }

        &:hover p.name {
          transform: translateY(-5px);
        }

        &:hover {
          filter: brightness(1.5);
        }

        .service-image {
          overflow: hidden;
          position: relative;
          height: 150px;
          display: flex;
          justify-content: center;

          img {
            width: 60px;
          }
        }

        p.name {
          transform: translateY(0);
          transition: transform 250ms ease;
          color: #fff;
          font-size: 13.5px;
          line-height: 26px;
          font-weight: 300;
          letter-spacing: 1px;
          padding: 0 8px;
        }

        .btn {
          margin-top: 15px;
        }

        &.video {
          padding: 0;

          video {
            display: block;
            border: none;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      blockquote {
        font-size: 18px;
        line-height: 36px;
        letter-spacing: 0.5px;
        font-weight: 700;
        font-style: italic;
        text-align: right;
        margin: 0;
        padding: 0;
        width: 500px;
        border-left: none;
        transform: translate(510px, 20px);

        strong {
          display: block;
          font-style: normal;
          font-size: 20px;
          padding-top: 10px;
        }
      }

      .box-hidden {
        width: 32%;
        visibility: hidden;
        background: transparent;
      }
    }
  }
}

// .content-about {
// 	margin-top: 30px;
// 	padding-bottom: 30px;
// 	background: #f8f8f8;

// 	.about-flexbox {
// 		display: flex;
// 		justify-content: space-between;
// 		flex-wrap: wrap;

// 		.flexbox-sidebar {
// 			width: 255px;

// 			.sidebar-heading {
// 				margin-top: 40px;
// 				background: @color2;
// 				padding: 20px 0;

// 				p {
// 					font-size: 16px;
// 					color: #fff;
// 					text-transform: uppercase;
// 					text-align: center;
// 				}
// 			}

// 			img {
// 				width: 100%;
// 			}

// 			ul {
// 				background: #4a4f55;
// 				border: none;
// 				padding: 20px;

// 				li {
// 					margin-bottom: 20px;
// 					float: none !important;
// 					position: relative;

// 					a {
// 						padding: 0;
// 						background: none;
// 						color: #fff;
// 						font-size: 16px;
// 						text-transform: uppercase;
// 						font-weight: 900;
// 						margin-bottom: 10px;
// 						border-radius: 0;
// 						border: none;
// 					}

// 					&.active {
// 						padding-left: 20px;

// 						&:before {
// 							content: '';
// 							position: absolute;
// 							height: 9px;
// 							width: 9px;
// 							border-radius: 50%;
// 							border: 1px solid #fff;
// 							background: @color2;
// 							top: 50%;
// 							left: 0;
// 							transform: translateY(-50%);
// 						}
// 					}

// 					&:last-child {
// 						margin-bottom: 0;

// 						a {
// 							margin-bottom: 0;
// 						}
// 					}
// 				}
// 			}
// 		}

// 		.flexbox-history {
// 			margin-top: 40px;
// 			width: 315px;

// 			p.history-heading {
// 				text-transform: uppercase;
// 				font-weight: 700;
// 				font-size: 16px;
// 			}

// 			ul {
// 				margin-top: 30px;
// 				border: none;

// 				li {
// 					margin-bottom: 20px;
// 					float: none !important;

// 					a {
// 						padding: 0;
// 						background: none;
// 						color: #333;
// 						font-size: 16px;
// 						margin-bottom: 10px;
// 						border-radius: 0;
// 						border: none;
// 					}

// 					&.active {
// 						padding-left: 20px;

// 						&:before {
// 							content: '';
// 							position: absolute;
// 							height: 9px;
// 							width: 9px;
// 							border-radius: 50%;
// 							border: 1px solid #fff;
// 							background: @color2;
// 							top: 50%;
// 							left: 0;
// 							transform: translateY(-50%);
// 						}
// 					}

// 					&:last-child {
// 						margin-bottom: 0;

// 						a {
// 							margin-bottom: 0;
// 						}
// 					}
// 				}
// 			}
// 		}

// 		.flexbox-body {
// 			margin-top: 40px;
// 			width: 445px;

// 			p.body-heading {
// 				text-transform: uppercase;
// 				font-weight: 700;
// 				font-size: 16px;
// 			}

// 			.body-description {
// 				p {
// 					font-size: 16px;
// 				}
// 			}
// 		}
// 	}
// }

div.prawo-category-section-content,
div.static-category-section-content {
  padding: 100px 0 80px 0;
  overflow: hidden;

  h2 {
    line-height: 54px;
    text-align: center;
    font-weight: 500;
    font-size: 40px;
    width: 50%;
    margin: 0 auto;
  }

  .flexbox-service {
    overflow: hidden;
    width: 72%;
    text-align: center;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    background-color: #263679;
    transition: all 250ms ease-in;

    &:hover p.name {
      transform: translateY(-5px);
    }

    &:hover {
      filter: brightness(1.5);
    }

    .service-image {
      overflow: hidden;
      position: relative;
      height: 150px;
      display: flex;
      justify-content: center;

      img {
        width: 60px;
      }
    }

    p.name {
      transform: translateY(0);
      transition: transform 250ms ease;
      color: #fff;
      font-size: 15.5px;
      line-height: 26px;
      font-weight: 300;
      letter-spacing: 1px;
      padding: 0 8px;
    }
  }

  .section-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 350px;
    margin: 100px 0 80px;

    .color-element {
      position: absolute;
      z-index: -1;
      background: #f1f1f1;
      height: 550px;
      width: 3000px;
      top: -110px;
      left: 880px;
    }

    .section-main-description {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;

      h3 {
        font-size: 22px;
        line-height: 34px;
        font-weight: 700;
      }

      p {
        font-size: 14px;
        line-height: 28px;
        color: #333;
        margin-top: 20px;
      }
    }

    .section-tile {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .flexbox-service {
        width: 72%;
        text-align: center;
        padding: 20px;
        overflow: visible;

        &.video {
          width: 90%;
          margin-bottom: 20px;
        }

        video {
          display: block;
          border: none;
          height: 100%;
          width: 100%;
          -o-object-fit: contain;
          object-fit: contain;
        }

        p.name {
          color: #fff;
        }

        &:hover {
          filter: brightness(1.5);

          p.name {
            transform: translateY(-5px);
          }
        }
      }
    }
  }

  .section-description {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 60px;

    &.reverse {
      position: relative;

      .color-element {
        position: absolute;
        z-index: -1;
        background: #f1f1f1;
        height: 450px;
        width: 3000px;
        top: -0px;
        right: 707px;
      }
    }

    .section-video {
      .text {
        margin-bottom: 30px;
      }

      .video {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        iframe {
          width: 48.5%;
          min-height: 200px;
          height: 220px;
          margin: 10px 0;
        }
      }
    }

    h3 {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 22px;
      line-height: 38px;
    }

    h4 {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      line-height: 38px;
      margin-top: 40px;
    }

    strong {
      font-size: 17px;
    }

    ul {
      text-align: left;
      list-style-type: none;
      padding-left: 18px;

      li {
        position: relative;
        padding: 10px 0;
        font-size: 15px;
        line-height: 32px;

        &:before {
          position: absolute;
          left: -17px;
          top: 22px;
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 20px;
          background: @color2;
        }
      }
    }

    p {
      font-size: 15px;
      color: #333;
      line-height: 32px;
      margin-top: 20px;
    }

    a {
      align-self: center;
      color: rgba(@color2, 0.8);
      font-size: 24px;
      margin-top: 20px;
      transition: color 250ms ease;

      &:hover {
        color: @color2;
      }
    }
  }

  .section-description-icon {
    padding-top: 60px;
    width: 80%;
    margin: 0 auto;

    h3 {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 22px;
      line-height: 38px;
      text-align: center;
    }

    .description-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding-top: 40px;

      .icon {
        width: 22%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        &:hover img {
          transform: scale(1.03);
        }

        img {
          width: 60px;
          transition: 300ms transform ease;
        }

        .heading {
          height: 150px;
          display: flex;
          align-items: center;

          p {
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            color: @color1;
            text-align: center;
          }
        }
      }
    }
  }
}

div.kancelaria-section-content {
  padding: 60px 0 100px 0;
  overflow: hidden;

  h2 {
    font-size: 40px;
    text-align: center;

    &.w-67 {
      width: 67%;
    }

    span {
      font-weight: 700;
    }
  }

  .section-description {
    margin-top: 20px;
    text-align: center;

    p {
      font-size: 16px;
      line-height: 28px;
      color: #333;
    }
  }

  .content-founders {
    padding-bottom: 150px;

    .founders-flexbox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;

      .color-element {
        position: absolute;
        z-index: -1;
        background: #f1f1f1;
        height: 700px;
        width: 3000px;
        top: -100px;
        left: 560px;
        background-color: #f1f1f1;
      }

      .flexbox-service {
        position: relative;
        width: 24%;
        background-color: #fff;
        margin-top: 60px;
        cursor: pointer;

        .service-description {
          position: absolute;
          bottom: -35px;
          right: 0;
          width: 88%;
          height: 70px;
          border: 1px solid rgba(0, 0, 0, 0.125);
          padding: 5px 10px 0 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          background-color: #fff;

          p.name,
          p.job {
            color: #333;
            line-height: 28px;
            letter-spacing: 1px;
            font-weight: 700;
          }

          p.name {
            font-size: 12.5px;
          }

          p.job {
            font-size: 10px;
          }
        }

        .service-image {
          overflow: hidden;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .box-hidden {
        width: 23%;
        background: transparent;
        outline: none;
        visibility: hidden;
      }
    }
  }

  .content-about {
    .about-flexbox {
      display: flex;
      flex-direction: column;
      padding-bottom: 150px;

      .flexbox-icons {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 30px;

        .flexbox-service {
          width: 24%;
          display: flex;
          flex-direction: column;
          align-items: center;

          &:hover span {
            transform: scale(1.04);
          }

          p {
            margin-top: 20px;
            font-weight: 700;
            font-size: 14px;
            color: @color1;
          }

          span {
            font-size: 70px;
            color: @color2;
            font-weight: 700;
            transition: 250ms transform;
          }
        }
      }
    }

    .flexbox-body {
      display: flex;
      align-items: center;
      padding-bottom: 80px;

      .body-description {
        p {
          font-size: 16px;
          line-height: 28px;
        }
      }
    }

    .content-description {
      display: flex;
      align-items: center;
      padding-bottom: 100px;
      position: relative;

      .color-element {
        position: absolute;
        z-index: -1;
        right: 775px;
        top: -75px;
        height: 110%;
        width: 3000px;
        background-color: #f1f1f1;
      }

      .flexbox-img {
        width: 50%;

        img {
          margin: 30px 0;
        }
      }

      .flexbox-description {
        width: 50%;
        padding-left: 30px;

        p {
          line-height: 28px;
        }
      }
    }
  }

  .cta-element {
    p {
      font-size: 28px;
      line-height: 50px;
      text-align: center;
    }
  }

  .modal {
    .modal-dialog {
      .modal-content {
        border-radius: 0;
        border: none;

        .modal-wrapper {
          display: flex;
          justify-content: space-between;
          padding: 0 15px;

          .modal-image {
            width: 40%;
            display: flex;
            align-items: center;

            img {
              width: 100%;
            }
          }

          .modal-body {
            width: 60%;
            padding: 30px 20px;

            p {
              margin-top: 0;
              font-size: 14px;
              line-height: 28px;
              letter-spacing: 1px;
            }
          }
        }

        .modal-header {
          display: flex;
          justify-content: space-between;

          h4 {
            font-size: 24px;
            width: 100%;
          }
        }

        .modal-footer {
          border: none;
        }
      }
    }
  }
}

div.kariera-section-content {
  padding: 30px 0 70px 0;

  h2.section-heading {
    font-size: 40px;
    text-align: center;
    font-weight: 500;
  }

  .section-description {
    margin-top: 20px;
    text-align: center;

    p {
      font-size: 16px;
      line-height: 30px;
      color: #333;
    }
  }

  .about-questions {
    margin-top: 50px;

    ul.nav-tabs {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;

      li {
        width: 40%;

        .flexbox-service {
          margin: 0;
          border: 3px solid transparent;
          text-align: center;
          padding: 10px 10px;
          color: #333;
          font-size: 18px;
          line-height: 30px;
          text-transform: uppercase;
          font-weight: 500;
          background: transparent;
          border-radius: 0;
        }

        &:hover,
        &.active {
          .flexbox-service {
            color: #fff;
            border-color: @color2;
            background-color: #263679;
          }
        }
      }
    }

    .tab-content {
      margin-top: 50px;

      .tab-pane {
        p.heading {
          margin-bottom: 20px;
          font-size: 20px;
          letter-spacing: 1px;
          font-weight: 500;
          text-transform: uppercase;
          color: #333;
          border: none;
          padding: 0;
          text-align: center;
          line-height: 20px;
        }

        p {
          border: 1px solid @gray-lighter;
          padding: 30px;
          color: @gray-light;
          letter-spacing: 1px;
          line-height: 26px;
        }
      }
    }
  }
}

div.kontakt-section-content {
  position: relative;
  padding-top: 30px;
  overflow: hidden;

  h2.section-heading {
    font-size: 26px;
    line-height: 40px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    font-weight: 300;
  }

  .form-flexbox {
    position: relative;
    display: flex;
    align-items: center;
    padding: 30px 0 50px 0;

    .color-element {
      position: absolute;
      z-index: -1;
      right: 660px;
      top: -20px;
      height: 110%;
      width: 3000px;
      background-color: #f1f1f1;
    }

    .flexbox-body {
      position: absolute;
      right: 0;
      width: 60%;

      iframe {
        border: none;
        width: 100%;
        height: 400px;
      }

      .form-service {
        label {
          font-size: 15px;
          font-weight: 400;
        }

        input {
          height: 40px;
          box-shadow: none;
        }

        textarea {
          box-shadow: none;
          height: 80px;
        }
      }
    }

    .flexbox-sidebar {
      width: 45%;
      padding-right: 50px;

      h3 {
        color: #333;
        font-size: 28px;
        margin-bottom: 15px;
      }

      p {
        color: #333;
        font-size: 16px;
        margin-bottom: 30px;
        text-align: left;
      }

      a {
        color: @color2;
        font-size: 20px;
        font-weight: 700;
        display: block;
        margin-bottom: 10px;
      }
    }
  }

  .content-info {
    padding: 30px 0 50px 0;
  }
}

div.section-footer {
  background: linear-gradient(
    135deg,
    rgba(34, 40, 73, 1) 0%,
    rgba(36, 48, 102, 1) 40%,
    rgba(36, 49, 106, 1) 49%,
    rgba(34, 40, 73, 1) 100%
  );
  padding: 50px 0;

  .footer-flexbox {
    display: flex;
    justify-content: space-between;

    .flexbox-service {
      &:not(last-of-type) {
        padding-right: 10px;
      }

      ul {
        li {
          margin-bottom: 10px;

          a {
            color: #fff;
            letter-spacing: 0.5px;

            img {
              width: 20px;
            }

            .logo {
              width: 130px;
            }
          }

          &.heading {
            text-transform: uppercase;
            color: #fff;
            font-weight: 300;
            font-size: 16px;
            margin-bottom: 10px;
            padding-bottom: 10px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        &.list-image {
          li {
            display: flex;
            align-items: center;
            letter-spacing: 0.5px;

            img {
              width: 20px;
            }

            a {
              display: flex;
              align-items: center;
            }

            span {
              padding-left: 10px;
              color: #fff;
            }
          }
        }
      }

      &.about {
        p {
          color: #fff;
          font-size: 13px;
          line-height: 22px;
          width: 210px;
          margin-top: 15px;
        }
      }
    }
  }

  .alert {
    background: none;
    border-radius: 0;
    border: none;
    border-top: 1px solid #636a72;
    padding: 30px 0 0 0;
    margin-top: 30px;
    margin-bottom: 0;

    p {
      font-size: 12px;
      color: #999;
      font-weight: 300;

      a {
        color: @color2;
        cursor: pointer;
      }
    }
  }

  .footer-credits {
    border-top: 1px solid #636a72;
    margin-top: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    p {
      color: #fff;
      font-size: 12px;
      opacity: 0.8;

      a {
        color: #fff;
      }
    }
  }
}

@media (max-width: 1500px) {
  div.prawo-dla-firm-section-content {
    .content-category {
      .about-flexbox {
        blockquote {
          transform: translate(350px, 25px);
        }
      }
    }
  }
}

@media (max-width: @screen-md-max) {
  div.section-header {
    nav.navbar {
      .container {
        display: block;
      }

      .navbar-collapse {
        border-top: none;
        box-shadow: none;
      }
    }
  }

  div.section-about {
    .about-flexbox {
      .flexbox-sidebar {
        width: 270px;
      }

      .flexbox-history {
        width: 255px;
      }

      .flexbox-body {
        width: 330px;
      }
    }
  }

  div.prawo-dla-firm-section-content,
  div.prawo-na-co-dzien-section-content {
    .heading {
      .color-element {
        left: 460px;
      }
    }

    .content-category {
      .about-flexbox {
        blockquote {
          transform: translate(300px, 25px);
        }
      }
    }
  }

  div.prawo-category-section-content,
  div.static-category-section-content {
    .section-wrapper {
      .color-element {
        left: 730px;
      }
    }
  }

  div.kancelaria-section-content {
    .content-about {
      .about-flexbox {
        .flexbox-sidebar {
          width: 270px;
        }

        .flexbox-history {
          width: 255px;
        }

        .flexbox-body {
          width: 330px;
        }
      }
    }
  }
}

@media (max-width: @screen-sm-max) {
  div.section-welcome {
    flex-direction: column;
    height: auto;

    .welcome-slogan {
      justify-content: center;
      width: 100%;
      text-align: center;
      padding: 20px 35px;
    }

    .welcome-video {
      width: 100%;
    }

    .owl-carousel-slider {
      width: 100%;

      .item {
        height: auto;
        text-align: center;
        justify-content: center;
      }
    }
  }

  div.section-offer {
    .offer-wrapper {
      .color-element {
        left: 354px;
      }

      .flexbox-service {
        width: 48%;
      }
    }
  }

  div.section-advantages {
    .fixed-slogan {
      left: 50px;
    }

    .advantages-slogan {
      margin-top: 30px;

      p {
        font-size: 22px;
      }
    }
  }

  div.section-about {
    padding-top: 50px;

    .section-description {
      span {
        &:nth-of-type(1) {
          left: -60px;
        }

        &:nth-of-type(2) {
          top: 150px;
          right: -50px;
        }
      }

      p {
        font-size: 20px;
      }
    }

    .section-news {
      .color-element {
        right: 340px;
      }

      .flexbox-news,
      .flexbox-video {
        width: 100%;

        .item {
          height: 75%;
        }
      }
    }
  }

  div.section-temporary {
    .section-content {
      .contact-item-wrapper {
        flex-direction: column;
        align-items: center;

        .contact-item {
          width: 44%;

          &:not(:first-of-type) {
            margin-top: 25px;
          }
        }
      }
    }

    .form-content {
      flex-direction: column;

      .content-heading {
        width: 100%;
        text-align: center;

        p {
          text-align: center;
        }
      }

      .form {
        width: 100%;
      }
    }
  }

  div.section-cta {
    .cta-flexbox {
      .flexbox-service {
        .color-element {
          width: 330px;
        }

        p {
          font-size: 26px;
        }
      }
    }
  }

  div.subpage-section-heading {
    h1 {
      font-size: 32px;
    }
  }

  div.aktualnosci-section-content {
    .content-flexbox {
      h3 {
        font-size: 18px;
      }

      .flexbox-body {
        width: 520px;

        .news-flexbox {
          .flexbox-service {
            width: 48%;
          }
        }
      }

      .flexbox-sidebar {
        width: 180px;

        .list-group {
          .video-wrapper {
            height: 150px;
          }

          .fancybox-item {
            height: 290px;
          }
        }
      }
    }
  }

  div.prawo-dla-firm-section-content,
  div.prawo-na-co-dzien-section-content {
    .heading {
      .color-element {
        left: 350px;
      }
    }

    .content-category {
      .about-flexbox {
        .flexbox-service {
          width: 47%;
        }

        blockquote {
          font-size: 16px;
          transform: translate(200px, 25px);
        }
      }
    }
  }

  div.prawo-category-section-content,
  div.static-category-section-content {
    .heading {
      h2 {
        width: 100%;
      }
    }

    .section-wrapper {
      flex-direction: column;
      height: 100%;
      padding-top: 40px;
      margin: 0;

      .section-main-description {
        width: 100%;
        padding-bottom: 40px;
      }

      .section-tile {
        width: 100%;

        .flexbox-service {
          width: 50%;
        }
      }

      .color-element {
        top: 200px;
        height: 400px;
        left: 360px;
      }
    }

    .section-description {
      &.reverse {
        .color-element {
          right: 290px;
        }
      }

      .section-video {
        flex-direction: column;

        .text {
          h3 {
            text-align: center;
          }
        }

        .video {
          iframe {
            width: 100%;
          }
        }
      }
    }

    .section-description-icon {
      .description-wrapper {
        .icon {
          width: 47%;
        }
      }
    }
  }

  div.kancelaria-section-content {
    .content-about {
      .content-description {
        flex-direction: column;

        .color-element {
          right: 365px;
          height: 450px;
        }

        .flexbox-description {
          width: 100%;
        }
      }

      .about-flexbox {
        .flexbox-icons {
          .flexbox-service {
            width: 47%;
          }
        }
      }
    }

    .content-founders {
      .founders-flexbox {
        .color-element {
          left: 340px;
          height: 600px;
        }

        .flexbox-service {
          width: 32%;

          .service-description {
            width: 90%;

            p.name {
              font-size: 12px;

              span {
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    .cta-element {
      p {
        font-size: 26px;
      }
    }
  }

  div.kontakt-section-content {
    .content-info {
      padding-top: 30px;
    }

    .form-flexbox {
      flex-direction: column;

      .flexbox-body {
        width: 100%;
        position: static;

        .form-service {
          width: 100%;
        }
      }

      .flexbox-sidebar {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-right: 0;

        p {
          text-align: center;
        }
      }
    }
  }

  div.section-footer {
    .footer-flexbox {
      flex-direction: column;
      align-items: center;

      .flexbox-service {
        margin-top: 30px;
        width: 80%;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;

        &.about {
          margin-top: 0;

          p {
            width: auto;
          }

          img {
            width: 70%;
            margin: 0 auto;
          }
        }

        .list-image {
          text-align: left;
        }
      }
    }

    .footer-credits {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

@media (max-width: @screen-xs-max) {
  .fixed-element {
    left: 15px;
    bottom: 40px;

    .element-wrapper {
      .element-active {
        width: 220px;

        a {
          span {
            font-size: 13px;
          }
        }
      }
    }
  }

  div.section-welcome {
    height: auto;

    .welcome-slogan {
      flex-direction: column;

      h1 {
        font-size: 18px;
      }

      h2 {
        font-size: 38px;
      }

      p {
        font-size: 16px;
        line-height: 28px;
      }

      .icons {
        align-self: center;
        padding-bottom: 30px;
      }
    }

    .owl-carousel-slider {
      .item {
        p {
          padding: 35px;
          font-size: 20px;
          line-height: 28px;
        }
      }

      .owl-controls {
        display: none;
      }
    }
  }

  div.section-advantages {
    .advantages-slogan {
      p {
        font-size: 20px;
        line-height: 40px;
      }
    }

    .advantages-flexbox {
      flex-wrap: wrap;

      .flexbox-service {
        width: 50%;
      }
    }
  }

  div.section-about {
    .section-description {
      p {
        font-size: 16px;
        line-height: 40px;
      }

      span {
        &:nth-of-type(1) {
          left: 0;
          top: -30px;
        }

        &:nth-of-type(2) {
          top: 150px;
          right: 0;
        }
      }

      p {
        font-size: 19px;
      }
    }

    .section-news {
      flex-direction: column;

      .flexbox-news {
        width: 100%;

        .news-list {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          li {
            width: 100%;
          }
        }
      }

      .flexbox-video {
        width: 100%;
        height: 400px;
        margin-top: 30px;

        iframe {
          height: 100%;
        }

        .btn-border {
          margin-top: 15px;
        }
      }
    }
  }

  div.section-temporary {
    .section-content {
      .contact-item-wrapper {
        .contact-item {
          width: 100%;
        }
      }
    }
  }

  div.subpage-section-heading {
    h1 {
      font-size: 22px;
    }
  }

  div.aktualnosci-section-content {
    .content-flexbox {
      flex-direction: column;

      .flexbox-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding-bottom: 30px;
      }

      .flexbox-sidebar {
        width: 100%;

        h3 {
          text-align: center;
          font-size: 21px;
        }

        .list-group {
          align-items: center;

          .color-element {
            left: 325px;
            height: 750px;
          }

          .video-wrapper {
            width: 60%;
            height: 200px;
          }
        }
      }
    }
  }

  div.prawo-dla-firm-section-content,
  div.prawo-na-co-dzien-section-content {
    .content-category {
      .about-flexbox {
        blockquote {
          transform: translate(100px, 25px);
        }
      }
    }
  }

  div.prawo-category-section-content,
  div.static-category-section-content {
    .section-wrapper {
      .color-element {
        left: 270px;
      }

      .section-tile {
        .flexbox-service {
          width: 70%;

          &.video {
            width: 100%;
          }
        }
      }

      .flexbox-service {
        .service-image {
          height: 110px;
        }

        p.name {
          font-size: 13px;
        }
      }
    }

    .section-description-icon {
      .description-wrapper {
        .icon {
          width: 100%;
        }
      }
    }

    .section-description {
      &.reverse {
        .color-element {
          right: 250px;
        }
      }
    }
  }

  div.kancelaria-section-content {
    .content-about {
      .about-flexbox {
        .flexbox-sidebar {
          width: 100%;
        }

        .tab-content {
          width: 100%;

          .flexbox-history {
            width: 100%;
          }
        }

        .flexbox-body {
          width: 100%;
        }
      }
    }
  }

  div.kariera-section-content {
    .about-questions {
      ul.nav-tabs {
        flex-direction: column;

        li {
          width: 100%;

          .flexbox-service {
            margin: 0;
            border: 3px solid transparent;
            text-align: center;
            padding: 10px 10px;
            color: #333;
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 500;
            background: transparent;
            border-radius: 0;
          }

          &:hover,
          &.active {
            .flexbox-service {
              color: #fff;
              border-color: @color2;
              background-color: #2c3e8b;
            }
          }
        }
      }

      .tab-content {
        margin-top: 50px;

        .tab-pane {
          p.heading {
            margin-bottom: 20px;
            font-size: 20px;
            letter-spacing: 1px;
            font-weight: 500;
            text-transform: uppercase;
            color: #333;
            border: none;
            padding: 0;
            text-align: center;
            line-height: 20px;
          }

          p {
            border: 1px solid @gray-lighter;
            padding: 30px;
            color: @gray-light;
            letter-spacing: 1px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  div.section-advantages {
    .fixed-slogan {
      display: none;
    }
  }

  div.section-about {
    .section-news {
      .color-element {
        right: 250px;
      }
    }
  }

  div.aktualnosci-section-content {
    .content-flexbox {
      .flexbox-body {
        .news-flexbox {
          .flexbox-service {
            width: 100%;
          }
        }
      }

      .flexbox-sidebar {
        .list-group {
          .color-element {
            left: 262px;
          }
        }
      }
    }
  }

  div.prawo-dla-firm-section-content,
  div.prawo-na-co-dzien-section-content {
    .heading {
      padding-bottom: 40px;

      h2 {
        font-size: 32px;
      }

      .color-element {
        left: 165px;
        height: 1950px;
      }
    }

    h3 {
      font-size: 32px;
    }

    .content-category {
      padding: 0 0 50px 0;
      margin-top: 0;

      .about-flexbox {
        margin-top: 0;

        .flexbox-service {
          width: 100%;
        }

        blockquote {
          transform: translate(-20px, 50px);
        }
      }
    }
  }

  div.prawo-category-section-content,
  div.static-category-section-content {
    padding: 60px 0 80px 0;

    .heading {
      h2 {
        font-size: 32px;
      }
    }

    h2 {
      width: 100%;
      font-size: 24px;
      line-height: 42px;
    }

    .section-description {
      width: 100%;

      &.reverse {
        .color-element {
          right: 200px;
        }
      }

      h3 {
        font-size: 20px;
        text-align: center;
      }
    }

    .section-wrapper {
      .color-element {
        display: none;
      }

      .section-main-description {
        h3 {
          font-size: 18px;
        }
      }

      .section-tile {
        .flexbox-service {
          width: 100%;

          &.video {
            width: 100%;
          }
        }
      }
    }
  }

  div.kancelaria-section-content {
    .content-about {
      padding-top: 80px;

      .content-description {
        padding-bottom: 50px;

        .color-element {
          right: 260px;
        }

        .flexbox-img {
          width: 80%;
        }
      }

      .about-flexbox {
        .flexbox-icons {
          .flexbox-service {
            width: 100%;
          }
        }
      }
    }

    .content-founders {
      padding-bottom: 50px;

      .founders-flexbox {
        justify-content: center;

        .color-element {
          left: 230px;
          height: 650px;
        }

        .flexbox-service {
          width: 80%;
        }
      }
    }

    .cta-element {
      padding-top: 50px;

      p {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 400px) {
  div.section-offer {
    h2.section-heading {
      font-size: 34px;
    }

    .offer-wrapper {
      .color-element {
        left: 155px;
        height: 2440px;
      }

      .flexbox-service {
        width: 100%;
      }
    }
  }

  div.section-about {
    h3 {
      font-size: 34px;
    }

    h2.section-heading {
      font-size: 38px;
    }

    .section-description {
      p {
        font-size: 17px;
      }

      span:nth-of-type(2) {
        top: 280px;
      }
    }

    .section-news {
      .color-element {
        right: 50px;
      }
    }
  }

  div.section-advantages {
    h2.section-heading {
      font-size: 24px;
    }

    .advantages-slogan {
      margin-top: 0;

      p {
        margin: 0;
        width: 100%;
      }
    }

    .advantages-flexbox {
      .flexbox-service {
        width: 100%;
      }
    }
  }

  div.section-cta {
    padding: 30px 0;

    .cta-flexbox {
      .flexbox-service {
        .color-element {
          height: 310px;
          bottom: -85px;
        }

        p {
          font-size: 24px;
          line-height: 40px;

          &:nth-of-type(1),
          &:nth-of-type(2) {
            text-align: center;
          }
        }

        a {
          width: 100%;
          font-size: 24px;
          margin-bottom: 30px;

          &:nth-of-type(1),
          &:nth-of-type(2) {
            text-align: center;
          }
        }
      }
    }
  }

  div.aktualnosci-section-content {
    .content-flexbox {
      .flexbox-sidebar {
        .list-group {
          .color-element {
            left: 167px;
          }

          .video-wrapper {
            width: 100%;
          }
        }
      }
    }
  }

  div.kancelaria-section-content {
    h2 {
      font-size: 32px;
    }

    .content-about {
      .content-description {
        .color-element {
          right: 180px;
        }
      }
    }

    .content-founders {
      .founders-flexbox {
        .color-element {
          left: 160px;
        }
      }
    }

    .cta-element {
      p {
        line-height: 38px;
      }
    }
  }
}
